import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ProductGroup from '../components/organisms/ProductGroup';
import CalloutCard from '../components/molecules/CalloutCard';
import MembershipCard from '../components/molecules/MembershipCard';
import FooterImage from '../assets/uf_main.inline.svg';
import JungleImage from '../assets/uf_jungle.inline.svg';

const TwoColumn = styled.div`
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    > div {
      margin: 0;
    }
  }
`;

function IndexPage( { data } ) {

  return (
    <>
      <Seo title="Home"/>
      <CalloutCard link="/about" text="We all know it was founded in the heart of a magic mountain star stream, but what is Unicorns Forever?" linkTitle="Find out about us"/>
      <ProductGroup products={data.allEtsyListing.nodes}/>
      <TwoColumn>
        <CalloutCard image="recycle-00004.jpg" link="/recycle" text="When you grow out of it, return it for discounts." linkTitle="See our recycling program"/>
      </TwoColumn>
      <FooterImage/>
    </>
  )
}

export default IndexPage;

export const indexPageQuery = graphql`
    query MyQuery {
        allEtsyListing(
          sort: {fields: featured_rank, order: ASC},
          filter: {featured_rank: {gte: 0}}
        ) {
          nodes {
            title
            url
            currency_code
            price
            description
            id
            featured_rank
            taxonomy_path
            childrenEtsyListingImage {
              childFile {
                childImageSharp {
                  gatsbyImageData( 
                    breakpoints: 1000
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
    }
`;

