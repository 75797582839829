export const convertToSlug = (Text) => {
  return '/' + Text
  .toLowerCase()
  .replace(/[^\w ]+/g,'')
  .replace(/ +/g,'-');
};

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const realTitles = {
  gsetsy_listing_1068288522: 'This is a True True Story (large)',
  gsetsy_listing_1062766380: 'The Girl Who Always Said No',
  gsetsy_listing_1062787012: 'UF Magic Fun Colouring Book',
  gsetsy_listing_1076728305: 'This is a True True Story (small)',
  gsetsy_listing_1073606149: 'The princess rides to the skatepark',
  gsetsy_listing_1060707532: 'Would you like a flower from a flower?',
  gsetsy_listing_1080027851: 'Nice Manners, That\'s OK',
  gsetsy_listing_1064599440: 'We Always Are Nice to Each Other',
  gsetsy_listing_1066078814: 'What is that cat doing?',
  gsetsy_listing_1078559865: 'What is that cat doing?',
  gsetsy_listing_1064611030: 'Universe surfing snake sharks',
  gsetsy_listing_1064609856: 'Nice Manners, That\'s OK',
  gsetsy_listing_1078556235: 'Ice creams for lunch',
  gsetsy_listing_1078552881: 'Fairies on Flowers and Dolphins',
  gsetsy_listing_1064598346: 'We Always Are Nice to Each Other',
  gsetsy_listing_1078545337: 'Boss, where should I put this crystal camel?',
  gsetsy_listing_1064595820: 'The angel guards the presents',
  gsetsy_listing_1078541513: 'The angel guards the presents',
  gsetsy_listing_1062268304: 'Sun moon wrestle time',
  gsetsy_listing_1062267052: 'Oh my gosh, that\'s the best',
  gsetsy_listing_1076217493: 'This is a beautiful star reindeer',
  gsetsy_listing_1062263752: 'My hoofs have glue on them',
  gsetsy_listing_1062258178: 'I love you',
  gsetsy_listing_1076209443: 'My hoofs have glue on them',
  gsetsy_listing_1060821014: 'The princess rides to the skatepark',
  gsetsy_listing_1074696051: 'Would you like a large brick with wings for lunch?',
  gsetsy_listing_1060737478: 'Sun moon wrestle time',
  gsetsy_listing_1074693259: 'My hoofs have glue on them',
  gsetsy_listing_1060735288: 'Duck mermaid love fish',
  gsetsy_listing_1060731020: 'Would you like a flower from a flower?',
  gsetsy_listing_1060729244: 'The princess rides to the skatepark',
}