import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProductCard from '../molecules/ProductCard';
import { Link } from 'gatsby';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  border: 1px dashed;
  padding: 2rem 2rem 0 2rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

const TitleRow = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    line-height: 1;
    margin: 0;
  }
  p {
    @media( ${props => props.theme.breakpoints.md} ) { 
      text-align: right;
      margin: 0;
    }
  }
`;

function ProductGroup( { title, products, viewAllLink } ) {
  return (
    <Holder className="product-group">
      {title && <TitleRow>
        <h2>{title}</h2>
        {viewAllLink && <p><Link to="/products">View All</Link></p>}
      </TitleRow>}
      {products.map( node =>
        <ProductCard key={node.id} product={node}/>
      )}
    </Holder>
  )
}

ProductGroup.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  viewAllLink: PropTypes.bool,
  products: PropTypes.array.isRequired,
};

ProductGroup.defaultProps = {
  title: 'Latest Products',
  viewAllLink: true,
};

export default ProductGroup;
