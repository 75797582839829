import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import Image from '../atoms/Image';
import {GatsbyImage} from "gatsby-plugin-image";
import MarkdownConverter from "../atoms/MarkdownConverter";
import {realTitles} from "../../utils/helpers";

const flip = keyframes`
  0% { opacity: 0; }
  49% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
`;

const ImageHolder = styled.div`
  &:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: ${flip} 6s linear infinite;
    @media ( ${props => props.theme.breakpoints.md} ) {
      animation: none;
    }
  }
`;

const Holder = styled.div`
  margin-bottom: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    &:hover {
      ${ImageHolder}:last-child {
        opacity: 1;
      }
    }
  }

  > a {
    display: block;
    position: relative;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
  
  .markdown-converter {
    p { margin: 0.25rem 0; }
  }
`;

const TitleHolder = styled.div`
  margin: 2rem 0 0.5rem 0;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0.5rem;

  
  .price-holder {
    text-align: right;
    line-height: 1.2;
    ${props => props.theme.fluidType(3)};
    .currency {
      ${props => props.theme.fluidType(0)};
      transform: translateY(-0.5rem);
      display: inline-block;
    }
  }
  h3, p {
    margin: 0;
  }
  h3 {
    margin-bottom: 0.25rem;
  }

  .subhead {
    text-transform: capitalize;
    margin: 0;
  }
`;

function ProductCard({product}) {
  let productType = 'Tee';
  if (product.taxonomy_path.includes("Books")) {
    productType = 'Book';
  }
  return (
    <Holder>
      <Link to={`/product/${product.id}`}>
        <ImageHolder>
          <GatsbyImage
            alt={product.title}
            image={product.childrenEtsyListingImage[0].childFile.childImageSharp.gatsbyImageData} />
        </ImageHolder>
        <ImageHolder>
          <GatsbyImage
            alt={product.title}
            image={product.childrenEtsyListingImage[1].childFile.childImageSharp.gatsbyImageData} />
        </ImageHolder>
      </Link>
      <TitleHolder>
        <div>
          <h3>{realTitles[product.id] || product.title}</h3>
          <p className="subhead">
            <em>Product - {productType}</em>
          </p>
        </div>
        <p className="price-holder"><span className="price">${product.price}</span><br/><span className="currency">({product.currency_code})</span></p>
      </TitleHolder>
      <MarkdownConverter content={product.description} />
    </Holder>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCard;
