import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  position: relative;
  > :first-child {
    opacity: ${props => props.show ? 0 : 1};
  }
  > :last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: ${props => props.show ? 'block' : 'none'};
  }
`;

function Gif( { children } ) {

  const [ show, setShow ] = useState( 0 );

  useEffect( () => {
    // Set automatic carousel move
    const flick = setInterval( () => {
      setShow( !show );
    }, 1000 );

    // Cleanup
    return () => {
      clearInterval( flick );
    }
  } );

  return (
    <Holder show={show}>{children}</Holder>
  )
}

Gif.propTypes = {
  children: PropTypes.array.isRequired
};

export default Gif;
