import React from 'react';
import Gif from '../atoms/Gif';
import Image from '../atoms/Image';

function MembershipCard() {
    return (
      <Gif>
        <div><Image imgName="website-4.jpg"/></div>
        <div><Image imgName="website-3.jpg"/></div>
      </Gif>
    )
}

export default MembershipCard;
