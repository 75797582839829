import React, { Component } from 'react';
import showdown from 'showdown';
import PropTypes from 'prop-types';

const converter = new showdown.Converter({simpleLineBreaks: true});

class MarkdownConverter extends Component {
    render() {
        return (
          <div className="markdown-converter" dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.content)}}/>
        )
    }
}

MarkdownConverter.propTypes = {
    content: PropTypes.string
};

export default MarkdownConverter;
