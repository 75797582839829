import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../atoms/Image';

const Holder = styled.div`
  border: 1px dashed;
  padding: 2rem;
  margin-bottom: 2rem;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

function CalloutCard({text, linkTitle, link, image}) {
    return (
      <Holder>
        {image && typeof image === 'string' && <Image imgName={image}/>}
        {image && typeof image !== 'string' && image}
        <p className={image ? 'h3' : 'h1' }>{text}</p>
        <p><Link to={link}>{linkTitle}</Link></p>
      </Holder>
    )
}

CalloutCard.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  text: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CalloutCard;
